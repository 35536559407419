.table-test {
    table-layout: auto !important;
    background-color: white;
    border: 0px;
}

thead tr {
    font-weight: bold;
}

#mapping_table tbody {
    display: block;
    max-height: 500px;
    overflow-y: scroll;
}

#mapping_table thead{
    display:table;
    width: 1000px;
    table-layout:fixed;
}

#mapping_table th {
    font-size: 12px;
    border-bottom: 0px;
}

#mapping_table td {
    font-size: 12px;
}

#mapping_table tbody tr {
    display:table;
    width: 1000px;
    table-layout:fixed;
}

.hidden-column {
 background-color: #e2f0f1;
 border-color:  #e2f0f1 !important;
}

.orange-hidden-column {
 background-color: #fbf0ee;
 border-color:  #fbf0ee !important;
}